var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "validation-observer",
        { ref: "simpleRules" },
        [
          _c("form-component", {
            attrs: {
              showHeader: true,
              copyData: _vm.locationCopy,
              showStatus: _vm.location.id,
              copyAddress: _vm.addressCopy,
              addressField: "address",
            },
            on: {
              "update:copyData": function ($event) {
                _vm.locationCopy = $event
              },
              "update:copy-data": function ($event) {
                _vm.locationCopy = $event
              },
              "update:copyAddress": function ($event) {
                _vm.addressCopy = $event
              },
              "update:copy-address": function ($event) {
                _vm.addressCopy = $event
              },
              save: _vm.validationForm,
            },
            scopedSlots: _vm._u([
              {
                key: "header",
                fn: function () {
                  return [
                    _vm._v(
                      "\n        " +
                        _vm._s(
                          _vm.locationCopy.id
                            ? _vm.locationCopy.name
                            : "Add New Location"
                        ) +
                        "\n      "
                    ),
                  ]
                },
                proxy: true,
              },
              {
                key: "body",
                fn: function () {
                  return [
                    _c(
                      "b-form",
                      [
                        _c(
                          "b-row",
                          [
                            _c(
                              "b-col",
                              { attrs: { md: "4", sm: "6" } },
                              [
                                _c(
                                  "b-form-group",
                                  {
                                    attrs: {
                                      label: "Location Name",
                                      "label-for": "basicInput",
                                    },
                                  },
                                  [
                                    _c("validation-provider", {
                                      attrs: {
                                        name: "Location Name",
                                        rules: "required",
                                      },
                                      scopedSlots: _vm._u([
                                        {
                                          key: "default",
                                          fn: function (ref) {
                                            var errors = ref.errors
                                            return [
                                              _c("b-form-input", {
                                                attrs: {
                                                  id: "basicInput",
                                                  placeholder: "",
                                                },
                                                model: {
                                                  value: _vm.locationCopy.name,
                                                  callback: function ($$v) {
                                                    _vm.$set(
                                                      _vm.locationCopy,
                                                      "name",
                                                      $$v
                                                    )
                                                  },
                                                  expression:
                                                    "locationCopy.name",
                                                },
                                              }),
                                              _c(
                                                "small",
                                                { staticClass: "text-danger" },
                                                [_vm._v(_vm._s(errors[0]))]
                                              ),
                                            ]
                                          },
                                        },
                                      ]),
                                    }),
                                  ],
                                  1
                                ),
                                _c(
                                  "b-row",
                                  [
                                    _c(
                                      "b-col",
                                      { attrs: { cols: "12" } },
                                      [
                                        _c(
                                          "b-form-group",
                                          {
                                            attrs: {
                                              label: "NPI Number",
                                              "label-for": "basicInput",
                                            },
                                          },
                                          [
                                            _c("validation-provider", {
                                              attrs: {
                                                name: "NPI Number",
                                                rules: "required",
                                              },
                                              scopedSlots: _vm._u([
                                                {
                                                  key: "default",
                                                  fn: function (ref) {
                                                    var errors = ref.errors
                                                    return [
                                                      _c("b-form-input", {
                                                        attrs: {
                                                          placeholder: "",
                                                        },
                                                        model: {
                                                          value:
                                                            _vm.locationCopy
                                                              .npiNumber,
                                                          callback: function (
                                                            $$v
                                                          ) {
                                                            _vm.$set(
                                                              _vm.locationCopy,
                                                              "npiNumber",
                                                              $$v
                                                            )
                                                          },
                                                          expression:
                                                            "locationCopy.npiNumber",
                                                        },
                                                      }),
                                                      _c(
                                                        "small",
                                                        {
                                                          staticClass:
                                                            "text-danger",
                                                        },
                                                        [
                                                          _vm._v(
                                                            _vm._s(errors[0])
                                                          ),
                                                        ]
                                                      ),
                                                    ]
                                                  },
                                                },
                                              ]),
                                            }),
                                          ],
                                          1
                                        ),
                                      ],
                                      1
                                    ),
                                  ],
                                  1
                                ),
                                _c(
                                  "b-row",
                                  [
                                    _c(
                                      "b-col",
                                      { attrs: { cols: "6" } },
                                      [
                                        _c(
                                          "b-form-group",
                                          {
                                            attrs: {
                                              label: "Short Code",
                                              "label-for": "basicInput",
                                            },
                                          },
                                          [
                                            _c("validation-provider", {
                                              attrs: {
                                                name: "Short Code",
                                                rules: "required",
                                              },
                                              scopedSlots: _vm._u([
                                                {
                                                  key: "default",
                                                  fn: function (ref) {
                                                    var errors = ref.errors
                                                    return [
                                                      _c("b-form-input", {
                                                        attrs: {
                                                          placeholder: "",
                                                        },
                                                        model: {
                                                          value:
                                                            _vm.locationCopy
                                                              .code,
                                                          callback: function (
                                                            $$v
                                                          ) {
                                                            _vm.$set(
                                                              _vm.locationCopy,
                                                              "code",
                                                              $$v
                                                            )
                                                          },
                                                          expression:
                                                            "locationCopy.code",
                                                        },
                                                      }),
                                                      _c(
                                                        "small",
                                                        {
                                                          staticClass:
                                                            "text-danger",
                                                        },
                                                        [
                                                          _vm._v(
                                                            _vm._s(errors[0])
                                                          ),
                                                        ]
                                                      ),
                                                    ]
                                                  },
                                                },
                                              ]),
                                            }),
                                          ],
                                          1
                                        ),
                                      ],
                                      1
                                    ),
                                    _c(
                                      "b-col",
                                      { attrs: { cols: "6" } },
                                      [
                                        _c(
                                          "b-form-group",
                                          {
                                            attrs: {
                                              label: "POS Code",
                                              "label-for": "basicInput",
                                            },
                                          },
                                          [
                                            _c("validation-provider", {
                                              attrs: {
                                                name: "POS Code",
                                                rules: "required",
                                              },
                                              scopedSlots: _vm._u([
                                                {
                                                  key: "default",
                                                  fn: function (ref) {
                                                    var errors = ref.errors
                                                    return [
                                                      _c("b-form-select", {
                                                        attrs: {
                                                          options:
                                                            _vm.posOptions,
                                                          "text-field": "code",
                                                          "value-field": "id",
                                                          placeholder: "",
                                                        },
                                                        model: {
                                                          value:
                                                            _vm.locationCopy
                                                              .placeOfServiceId,
                                                          callback: function (
                                                            $$v
                                                          ) {
                                                            _vm.$set(
                                                              _vm.locationCopy,
                                                              "placeOfServiceId",
                                                              $$v
                                                            )
                                                          },
                                                          expression:
                                                            "locationCopy.placeOfServiceId",
                                                        },
                                                      }),
                                                      _c(
                                                        "small",
                                                        {
                                                          staticClass:
                                                            "text-danger",
                                                        },
                                                        [
                                                          _vm._v(
                                                            _vm._s(errors[0])
                                                          ),
                                                        ]
                                                      ),
                                                    ]
                                                  },
                                                },
                                              ]),
                                            }),
                                          ],
                                          1
                                        ),
                                      ],
                                      1
                                    ),
                                  ],
                                  1
                                ),
                                _c(
                                  "can",
                                  { attrs: { I: "read", a: "ClaimRules" } },
                                  [
                                    _c(
                                      "b-form-group",
                                      { attrs: { label: "Bill Patient" } },
                                      [
                                        _c("validation-provider", {
                                          staticClass:
                                            "demo-inline-spacing pt-0",
                                          attrs: {
                                            name: "Bill Patient",
                                            rules: "required",
                                          },
                                          scopedSlots: _vm._u([
                                            {
                                              key: "default",
                                              fn: function (ref) {
                                                var errors = ref.errors
                                                return [
                                                  _c(
                                                    "b-form-radio",
                                                    {
                                                      staticClass: "pt-0 mt-0",
                                                      attrs: {
                                                        name: "Bill Patient",
                                                        value: "true",
                                                        required: "",
                                                      },
                                                      model: {
                                                        value:
                                                          _vm.locationCopy
                                                            .billPatient,
                                                        callback: function (
                                                          $$v
                                                        ) {
                                                          _vm.$set(
                                                            _vm.locationCopy,
                                                            "billPatient",
                                                            $$v
                                                          )
                                                        },
                                                        expression:
                                                          "locationCopy.billPatient",
                                                      },
                                                    },
                                                    [
                                                      _vm._v(
                                                        "\n                      Yes\n                    "
                                                      ),
                                                    ]
                                                  ),
                                                  _c(
                                                    "b-form-radio",
                                                    {
                                                      staticClass: "pt-0 mt-0",
                                                      attrs: {
                                                        name: "Bill Patient",
                                                        value: "false",
                                                        required: "",
                                                      },
                                                      model: {
                                                        value:
                                                          _vm.locationCopy
                                                            .billPatient,
                                                        callback: function (
                                                          $$v
                                                        ) {
                                                          _vm.$set(
                                                            _vm.locationCopy,
                                                            "billPatient",
                                                            $$v
                                                          )
                                                        },
                                                        expression:
                                                          "locationCopy.billPatient",
                                                      },
                                                    },
                                                    [
                                                      _vm._v(
                                                        "\n                      No\n                    "
                                                      ),
                                                    ]
                                                  ),
                                                  _c(
                                                    "small",
                                                    {
                                                      staticClass:
                                                        "text-danger",
                                                    },
                                                    [_vm._v(_vm._s(errors[0]))]
                                                  ),
                                                ]
                                              },
                                            },
                                          ]),
                                        }),
                                      ],
                                      1
                                    ),
                                  ],
                                  1
                                ),
                              ],
                              1
                            ),
                            _c(
                              "b-col",
                              { attrs: { md: "4", sm: "6" } },
                              [
                                _c("address-component", {
                                  model: {
                                    value: _vm.addressCopy,
                                    callback: function ($$v) {
                                      _vm.addressCopy = $$v
                                    },
                                    expression: "addressCopy",
                                  },
                                }),
                              ],
                              1
                            ),
                            _c(
                              "b-col",
                              { attrs: { md: "4", sm: "6" } },
                              [
                                _c("email-phone-fax", {
                                  attrs: {
                                    phones: _vm.locationCopy.phones,
                                    emails: _vm.locationCopy.emails,
                                  },
                                  on: {
                                    "update:phones": function ($event) {
                                      return _vm.$set(
                                        _vm.locationCopy,
                                        "phones",
                                        $event
                                      )
                                    },
                                    "update:emails": function ($event) {
                                      return _vm.$set(
                                        _vm.locationCopy,
                                        "emails",
                                        $event
                                      )
                                    },
                                  },
                                }),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                  ]
                },
                proxy: true,
              },
            ]),
            model: {
              value: _vm.location,
              callback: function ($$v) {
                _vm.location = $$v
              },
              expression: "location",
            },
          }),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }